.ant-modal-mask {
  background-color: #152c5b;
  opacity: 0.8;
}


.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected>.ant-menu-submenu-title {
  border-left: 4px solid #1570ef;
  border-radius: 5px;
  background: #e6f4ff;
  color: #0062eb;
}

.ant-menu-submenu {

  &>.ant-menu>.ant-menu-item-selected,
  .ant-menu>.ant-menu-submenu-selected>.ant-menu-submenu-title {
    border-left: none;
    border-radius: 0;
    background: #e6f4ff;
    color: #0062eb;
  }
}

.ant-menu>.ant-menu-submenu-selected && .ant-menu-item-selected {
  border-left: none;
  border-radius: 5px;
  background-color: white;
}

.ant-menu>.ant-menu-submenu-selected && .ant-menu-item-selected>.ant-menu-title-content {
  color: #0062eb;
}

.ant-menu>.ant-menu-submenu-selected && .ant-menu-item-selected::after,
.ant-menu>.ant-menu-submenu-selected && .ant-menu-item-selected {
  background-color: #fafafa !important;
}

.ant-menu>.ant-menu-submenu-selected && .ant-menu-item {
  color: rgb(150, 160, 181);
  border: none;
}

.ant-menu-submenu-open {
  border-left: none;
  border-radius: 5px;
}

.ant-menu-submenu-popup ul {
  left: -4rem;
  position: absolute;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
}

.ant-layout-header {
  background-color: #fff;
}

.ant-picker-panel-container {
  font-family: 'Inter';
}

.ant-btn-compact-last-item {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
}

.ant-spin-dot-item {
  background-color: #1570ef !important;
}

.ant-spin-item {
  background: #1570ef !important;
}

.ant-spin-text {
  color: #1570ef !important;
}

.ant-table-column-title {
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  text-decoration: e;
}

.menu-root.ant-menu-inline {
  box-shadow: none;
  padding-right: 0.8rem;
}

.ant-card-body {
  padding: 10px !important;
}

.ant-btn {
  border-radius: 6px !important;
  font-family: 'Inter' !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  // height: 2rem !important;

  &.borderRadiusLG {
    border-radius: 8px !important;
  }

  &.borderRadiusSM {
    border-radius: 4px !important;
  }

  &.borderRadiusXS {
    border-radius: 2px !important;
  }
}

.ant-btn-primary {
  // color: #ffffff !important;
  border-color: #1570ef !important;
  background: #1570ef !important;
  // text-shadow: none !important;
  // box-shadow: 0px 2px 0px 0px rgba(5, 145, 255, 0.1) !important;

  // &:hover {
  //   border-color: #4096ff !important;
  //   background-color: #4096ff !important;
  //   color: #ffffff !important;
  // }

  // &:focus,
  // &:active {
  //   border-color: #0958d9 !important;
  //   background-color: #0958d9 !important;
  //   color: #ffffff !important;
  // }

  // &>svg+span,
  // &>span+svg {
  //   margin-left: 8px;
  // }
}

.ant-btn-default {
  // color: rgba(0, 0, 0, 0.88) !important;
  // box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02) !important;
  // text-shadow: none !important;

  // &.icon-black {
  //   svg {
  //     path {
  //       fill: rgba(0, 0, 0, 0.88) !important;
  //     }
  //   }

  // }

  &>svg+span,
  &>span+svg {
    margin-left: 8px;
  }

  // &:hover {
  //   color: #4096ff !important;
  //   border-color: #4096ff !important;
  //   background: #fff;

  //   svg {
  //     path {
  //       fill: #4096ff !important;
  //     }
  //   }
  // }

  // &:focus,
  // &:active {
  //   // color: #0958d9 !important;
  //   border-color: #0958d9 !important;
  //   background: #fff;

  //   // svg {
  //   //   path {
  //   //     fill: #0958d9 !important;
  //   //   }
  //   // }
  // }
}

.ant-input-search .ant-input:hover {
  border-color: #4096ff !important;
}


.ant-input-search .ant-input:focus {
  border-color: #0958d9 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #4096ff !important;
}

input,
button,
select,
optgroup,
textarea {
  font-family: 'Inter';
}

.ant-switch {
  border-radius: 20px !important;
}

.ant-table-thead>tr>th {
  background-color: #F5F5F5 !important;
}

.ant-picker {
  border-radius: 6px !important;

  &.borderRadiusLG {
    border-radius: 8px !important;
  }

  &.borderRadiusSM {
    border-radius: 4px !important;
  }

  &.borderRadiusXS {
    border-radius: 2px !important;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;

  &.borderRadiusLG {
    border-radius: 8px !important;
  }

  &.borderRadiusSM {
    border-radius: 4px !important;
  }

  &.borderRadiusXS {
    border-radius: 2px !important;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #1570ef !important;
}

.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    border-radius: 6px !important;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #1570ef !important;
      border-color: #1570ef !important;
    }
  }

  &.borderRadiusLG {
    .ant-checkbox-inner {
      border-radius: 8px !important;
    }
  }

  &.borderRadiusSM {
    .ant-checkbox-inner {
      border-radius: 4px !important;
    }
  }

  &.borderRadiusXS {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-radius: 2px !important;
      }
    }
  }
}

.ant-btn-link {
  color: #1570ef !important;
}

.ant-menu-item-selected>.ant-menu-item-icon {
  color: #1570ef !important;

  path {
    fill: #1570ef !important;
  }
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #1570ef !important;

  & span {
    color: #1570ef !important;
  }

  &>.ant-menu-item-icon {
    path {
      fill: #1570ef !important;
    }
  }
}

.ant-menu-item:hover {
  &>.ant-menu-item-icon {
    path {
      fill: #1570ef !important;
    }
  }
}

.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected>.ant-menu-submenu-title {
  &>.ant-menu-item-icon {
    margin-left: -4px !important;

    path {
      fill: #1570ef !important;
    }
  }
}

.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px !important;
}

.ant-table-cell-row-hover {
  color: #1570ef !important;

}

.ant-table-body {
  overflow: auto auto !important;
}

.ant-table-container {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ant-table {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-top: 1px solid #CFD2D7;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border-top: none !important;
}

.ant-table.ant-table-bordered>.ant-table-container {
  border-left: none !important;
}

.ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 8px !important;
}

.ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 8px !important;
}

.ant-table-header {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.ant-input {
  border-radius: 6px !important;
}

.ant-card {
  border-radius: 8px !important;
}