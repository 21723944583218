@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-joyride__overlay {
  position: fixed !important;
}

img {
  height: revert-layer;
  display: initial;
  max-width: 100%;
}
