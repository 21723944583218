.container {
  padding-top: 2rem;
  text-align: center;
  background-color: white;
  height: 100vh;
  font-family: 'Inter';
}
.title {
  text-align: center;
  margin-top: 2rem;
}
.guide {
  margin: 2rem 1rem;
  text-align: center;
}

.steps {
  margin: 2rem 1rem;
  text-align: left;
}

.copyIcon {
  cursor: pointer;
  padding-top: 6px;
}

.steps h5 {
  margin-top: 0;
}

.step_content {
  font-size: 15px;
  margin-top: 1.2rem;
  font-weight: 400;
}
.browser {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
}

.browser img {
  margin: 0 2% 0 2%;
}
.input {
  padding: 0.6rem 1rem;
  background-color: rgba(237, 228, 228, 0.351);
  border: 0.5px solid rgba(0, 0, 0, 0.299);
  font-style: 15px;
}
.btn {
  padding: 0.6rem 1rem;
  background: #1570ef;
  color: white;
}
.link {
  margin-top: 8px;
}
