.skip-link {
  margin-right: 1rem;
  position: absolute !important;
  transform: translateX(-200%);
  transition: transform 0.3s;

  &:focus {
    position: absolute !important;
    transform: translateX(0);
    z-index: 10000 !important;
    top: 1rem !important;
    left: 1rem !important;
  }
  span {
    text-transform: uppercase !important;
    font-size: 11px !important;
    line-height: 11px !important;
  }
}

@primary-color: #1570EF;@primary-color-active: #1570EF;@text-color: #152C5B;@font-family: Lexend, sans-serif;