.title {
  font-weight: 400;
  font-size: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.profile_div {
  display: flex;
  gap: 1rem;
  position: absolute;
  right: 1rem;
}

.menuIcon {
  align-self: center;
  margin: 0 1rem;
}

.menuIcon:hover {
  background: #152c5b;
}

.dropdown {
  background: #24447e;
  border-radius: 4px;
  padding: 1rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  border: 1px solid #445880;
  align-self: center;
}

.menuItem {
  display: flex;
  font-size: 15px;
  font-weight: 500;
  align-items: center;
  text-align: left;
  min-width: 15rem;
  padding: 0.2rem 0.4rem;
}

.menuIconAi {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 2rem;
  text-align: left;
  margin-right: 0.6rem;
}

.imgDiv {
  background-color: #fff;
  height: 30px;
  width: 30px;
  border: 1px solid #e5ebf0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.instituteTitle {
  color: #fff;
  font-size: 24;
  margin-left: 0.5rem;
}
.careerflowDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 2px solid #fff;
  padding: 0.2rem 1rem;
  margin-right: 1rem;
}
