.createbtn {
  margin: 1rem 0.2rem 0.6rem 0.2rem !important;
  background: var(--secondary-button-color, #1570ef) !important;
  border-radius: 6px !important;
  color: white !important;
  height: 2.4rem !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px !important;
}
.createbtn1 {
  margin: 1rem 0.2rem 0.6rem 0.2rem !important;
  background: white !important;
  border-radius: 6px !important;
  color: black !important;
  height: 2.4rem !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px !important;
}

.createbtn:hover,
.createbtn:focus,
.createbtn:active {
  border-color: #4280dc;
  background-color: #4280dc;
  color: white;
}
