.username {
  padding: 1rem 2rem;
}

.logout_btn {
  text-align: center;
  padding-bottom: 1rem;
}

.avatar {
  border: 1px solid white;
  border-radius: 2rem;
  padding: 0;
  margin-left: 8px;
}

.profileDiv {
  background-color: #e1edff;
  height: 2rem;
  padding: 8px;
  border-radius: 4px;
  color: black;
}

.menuItem {
  display: flex;
  font-size: 15px;
  font-weight: 500;
  align-items: center;
  text-align: left;
  min-width: 10rem;
  padding: 0.2rem 0.4rem;
}

.menuIconAi {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border-radius: 2rem;
  text-align: left;
  margin-right: 0.2rem;
  color: gray;
}
